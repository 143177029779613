import React from "react";
import {IoClose} from "react-icons/io5";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedView} from "../../redux/slice/tabSlice";
import "./View.scss";
import StudentTable from "./StudentTable/StudentTable";
import SessionTable from "./SessionTable/SessionTable";

const View = () => {
  const dispatch = useDispatch();
  const {eventName} = useSelector((state) => state.eventData);

  return (
    <div className="view-page">
      <div className="action-bar">
        <div className="event-title">{eventName}</div>
        <IoClose onClick={() => dispatch(setSelectedView("HOME"))} />
      </div>

      <div className="data-area">
        <StudentTable />
        <SessionTable />
      </div>
    </div>
  );
};

export default View;