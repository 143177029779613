import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const StudentTable = () => {
  const {eventDataList, sessionList} = useSelector((state) => state.eventData);

  const columnDefs = useMemo(() => {
    const cols = [
      {
        headerName: 'Zoom User ID',
        field: 'name',
        flex: 5,
        filter: true,
      },
    ];

    sessionList.forEach((session) => {
      cols.push({
        headerName: session,
        field: session,
        flex: 1,
        filter: "agNumberColumnFilter",
        valueGetter: (params) => {
          if(params.data[session + " duration"]) {
            return params.data[session + " duration_percentage"];
          } else {
            return -1;
          }
        },
        cellRenderer: (params) => {
          if(params.data[session + " duration"]) {
            return (
                <div className="attendance-cell">
                  <div className="minutes">{params.data[session + " duration"]}</div>
                  <div className="percent">({params.data[session + " duration_percentage"]}%)</div>
                </div>
            );
          } else {
            return (
                <div className="attendance-cell">
                  <div className="absent">-1</div>
                </div>
            );
          }
        },
      });
    });

    return cols;
  }, [sessionList]);

  return (
      <div className="student-data ag-theme-alpine">
        <AgGridReact
            columnDefs={columnDefs}
            rowData={Object.values(eventDataList)}
        />
      </div>
  );
}

export default StudentTable;
