import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer-section">
      <p>Designed & Developed for <a href="https://maatramfoundation.com/" target="_blank" rel="noreferrer">Maatram Foundation</a> by <a href="https://www.linkedin.com/in/hemantharajan-v-802273167/" target="_blank" rel="noreferrer">HemanthaRajan V</a></p>
    </div>
  )
}

export default Footer;