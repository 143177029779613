import React from 'react';
import Logo from '../../images/logo.png';
import './header.scss';
import {useSelector} from "react-redux";
import {FiLogOut} from "react-icons/fi";
import {  signOut } from "firebase/auth";
import {auth} from "../../config/firebase";
import {toast} from "react-toastify";

const Header = () => {
  const userEmail = useSelector((state) => state.user.userEmail);

  const logoutHandler = () => {
    signOut(auth).then(() => {
      toast.success("Signed out successfully")
    }).catch((error) => {
      console.error(error);
      toast.error("An error occurred while signing out. Please try again.");
    });
  };

  return (
    <div className="header-section">
      <img src={Logo} alt="Logo" />
      {
        userEmail ?
            <div className="profile-area">
              <div className="email">{userEmail}</div>
              <FiLogOut onClick={logoutHandler}/>
            </div>
            : null
      }
    </div>
  );
}

export default Header;