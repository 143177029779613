import './App.css';
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import ContentArea from "./Components/ContentArea/ContentArea";
import {useEffect} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./config/firebase";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setTokenLoading, setUserEmail} from "./redux/slice/userSlice";
import axios from "axios";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!Object.keys(axios.defaults.headers.common).includes("Authorization")) {
      dispatch(setTokenLoading(true));
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAxiosBearerToken(user);
        dispatch(setUserEmail(user.email));
      } else {
        dispatch(setUserEmail(""));
        navigate("/login");
      }
    });
  });

  const setAxiosBearerToken = (user) => {
    user.getIdToken().then((token) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      dispatch(setTokenLoading(false));
    });
  }

  return (
    <div className="App">
      <Header />
      <ContentArea />
      <Footer />
    </div>
  );
}

export default App;
