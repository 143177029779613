import React, {useEffect, useState} from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import "./Login.scss";
import {toast} from "react-toastify";
import {AiOutlineLoading} from "react-icons/ai";
import {signInWithEmailAndPassword, onAuthStateChanged} from 'firebase/auth';
import {auth} from "../../config/firebase";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
  });

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAxiosBearerToken(user);
        navigate("/");
      }
    });
  });

  const setAxiosBearerToken = (user) => {
    user.getIdToken().then((token) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    });
  }

  const updateFormField = (name, value) => {
    setFormFields({...formFields, [name]: value});
  };

  const validateForm = () => {
    const requiredFields = ['email', 'password'];

    const fieldNames = {
      email: "Email",
      password: "Password",
    };

    for (let field of requiredFields) {
      if (!formFields[field]) {
        toast.error(`Please fill out the ${fieldNames[field]} field.`);
        return false;
      }
    }
    return true;
  };

  const submitForm = async () => {
    if(isLoading) return;

    if (!validateForm()) return;

    setIsLoading(true);

    signInWithEmailAndPassword(auth, formFields.email, formFields.password)
        .catch((error) => {
          toast.error("Something went wrong. Please try again.");
          console.error(error.code, error.message)
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  return (
      <div className="Login">
        <Header/>
        <div className="content-area">
          <div className="dialog-wrapper">
            <div className="dialog">
              <div className="form">
                <h2>Login</h2>

                <label htmlFor="email">User Email</label>
                <input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Enter user email"
                    value={formFields["email"]}
                    onChange={(e) => updateFormField(e.target.name, e.target.value)}
                />

                <label htmlFor="password">Password</label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    value={formFields["password"]}
                    onChange={(e) => updateFormField(e.target.name, e.target.value)}
                />

                <div className="submit-button" onClick={() => submitForm()}>
                  {isLoading ? <AiOutlineLoading/> : "Submit"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  );
};

export default Login;