import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  selectedView: "HOME",
}

const tabSlice = createSlice({
  name: "tabSlice",
  initialState,
  reducers: {
    setSelectedView: (state, data) => {
      state.selectedView = data.payload;
    },
  }
});

export const {
  setSelectedView
} = tabSlice.actions;

export default tabSlice.reducer;
