import React, {useState} from "react";
import {FaPlus} from "react-icons/fa";
import Select from "react-dropdown-select";
import csvLogo from "../../../images/csvLogo.png";
import {IoMdCloseCircleOutline} from "react-icons/io";
import {AiOutlineLoading} from "react-icons/ai";
import {toast} from "react-toastify";
import axios from "axios";
import {baseUrl} from "../../../config";
import {useDropzone} from "react-dropzone";

const UpdateForm = ({events}) => {
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    isNewEvent: false,
    eventName: "",
    newEventName: "",
    sessionName: "",
    hostName: "",
    startDate: "",
    endDate: "",
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: { "text/csv": [".csv"] },
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);
      setSelectedFile(acceptedFiles);
    }
  });

  const updateFormField = (name, value) => {
    setFormFields({...formFields, [name]: value});
  }

  const validateForm = () => {
    const requiredFields = [formFields["isNewEvent"]? 'newEventName' : 'eventName', 'sessionName', 'hostName', 'startDate', 'endDate'];

    const fieldNames = {
      eventName: "event name",
      newEventName: "event name",
      sessionName: "session name",
      hostName: "host name",
      startDate: "start date",
      endDate: "end date",
    };

    for (let field of requiredFields) {
      if (!formFields[field]) {
        toast.error(`Please fill out the ${fieldNames[field]} field.`);
        return false;
      }
    }
    if (!selectedFile) {
      toast.error("Please attach a attendance report file.");
      return false;
    }
    return true;
  }

  const submitForm = async () => {
    if(isLoading) return;

    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('isNewEvent', formFields.isNewEvent);
    formData.append('eventName', formFields.eventName);
    formData.append('newEventName', formFields.newEventName);
    formData.append('sessionName', formFields.sessionName);
    formData.append('hostName', formFields.hostName);
    formData.append('startDate', formFields.startDate);
    formData.append('endDate', formFields.endDate);
    formData.append('attendanceFile', selectedFile[0]);

    try {
      setIsLoading(true);
      await axios.post(`${baseUrl}/upload-session`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(error.response.data || "Error in uploading session data. Please try again.");
    } finally {
      toast.success("Session data uploaded successfully");
      setIsLoading(false);
    }
  }

  return (
      <div className="form">
        <div className="select-mode-button"
             onClick={() => setFormFields({...formFields, "isNewEvent": !formFields["isNewEvent"]})}>
          <FaPlus/>
          <span>{formFields["isNewEvent"] ? "Select Existing Event" : "Create Event"}</span>
        </div>

        {formFields["isNewEvent"] ?
            (
                <>
                  <label className="first-label" htmlFor="eventName">Name of the Event</label>
                  <input
                      id="newEventName"
                      name="newEventName"
                      type="text"
                      placeholder="Enter event name eg: pv_sample"
                      value={formFields["newEventName"]}
                      onChange={(e) => updateFormField(e.target.name, e.target.value)}
                  />
                </>
            ) : (
                <>
                  <label className="first-label" htmlFor="eventName">Select a Event</label>
                  <Select
                      id="eventName"
                      name="eventName"
                      options={events}
                      onChange={(values) => {
                        updateFormField("eventName", values[0].value);
                      }}
                      values={[]}
                      placeholder="Select an option"
                  />
                </>
            )
        }

        <label htmlFor="sessionName">Name of the Session</label>
        <input
            id="sessionName"
            name="sessionName"
            type="text"
            placeholder="Enter session name"
            value={formFields["sessionName"]}
            onChange={(e) => updateFormField(e.target.name, e.target.value)}
        />

        <label htmlFor="hostName">Name of the Host</label>
        <input
            id="hostName"
            name="hostName"
            type="text"
            placeholder="Enter host name"
            value={formFields["hostName"]}
            onChange={(e) => updateFormField(e.target.name, e.target.value)}
        />

        <label htmlFor="startDate">Meeting Start Date & Time</label>
        <input
            id="startDate"
            type="datetime-local"
            name="startDate"
            value={formFields["startDate"]}
            onChange={(e) => updateFormField(e.target.name, e.target.value)}
        />

        <label htmlFor="endDate">Meeting End Date & Time</label>
        <input
            id="endDate"
            type="datetime-local"
            name="endDate"
            value={formFields["endDate"]}
            onChange={(e) => updateFormField(e.target.name, e.target.value)}
        />

        {selectedFile ?
            (
                <div className="csv-preview">
                  <img src={csvLogo} alt="Logo"/>
                  <IoMdCloseCircleOutline onClick={() => setSelectedFile(undefined)}/>
                  <p>{selectedFile[0].name}</p>
                </div>
            ) : (
                <>
                  <label htmlFor="reportFile">Attach Attendance Report</label>
                  <div {...getRootProps()}>
                    <input id="reportFile" {...getInputProps()} />
                    {
                      isDragActive ?
                          <p>Drop the files here ...</p> :
                          <p>Drag 'n' drop the attendance report csv in here, or click to select file</p>
                    }
                  </div>
                </>
            )
        }

        <div className="submit-button" onClick={() => submitForm()}>
          {isLoading ? <AiOutlineLoading/> : "Submit"}
        </div>
      </div>
  );
}

export default UpdateForm;