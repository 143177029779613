import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  eventName: "",
  eventDataList: {},
  sessionList: [],
  sessionKeyList: [],
}

const eventDataSlice = createSlice({
  name: "eventDataSlice",
  initialState,
  reducers: {
    setEventData: (state, data) => {
      let sessions = new Set([]);
      let sessionKeys = new Set([]);
      let result = {};

      data.payload.forEach((entry) => {
        let name = entry["name"];
        sessions.add(entry["session_name"])
        sessionKeys.add(entry["session_key"])

        if (result[name]) {
          result[name][entry["session_name"]+" duration"] = entry["duration"];
          result[name][entry["session_name"]+" duration_percentage"] = entry["duration_percentage"];
        } else {
          result[name] = {
            name: entry["name"],
            [entry["session_name"]+" duration"]: entry["duration"],
            [entry["session_name"]+" duration_percentage"]: entry["duration_percentage"],
          }
        }
      });

      state.eventDataList = result;
      state.sessionList = Array.from(sessions);
      state.sessionKeyList = Array.from(sessionKeys);
    },
    setEventName: (state, data) => {
      state.eventName = data.payload;
    }
  }
});

export const {
  setEventData, setEventName
} = eventDataSlice.actions;

export default eventDataSlice.reducer;
