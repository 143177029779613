import React from "react";
import "./ContentArea.scss";
import Home from "../Home/Home";
import {useSelector} from "react-redux";
import View from "../View/View";

const ContentArea = () => {
  const isTokenLoading = useSelector((state) => state.user.isTokenLoading);
  const selectedView = useSelector((state) => state.tab.selectedView);

  const views = {
    HOME: <Home />,
    VIEW: <View />,
  }

  if (isTokenLoading) {
    return (
        <div className="loader-area">
          <div>Loading...</div>
        </div>
    );
  }

  return (
    <div className="content-area">
      {views[selectedView]}
    </div>
  );
}

export default ContentArea;