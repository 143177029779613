import React, {useState} from "react";
import Select from "react-dropdown-select";
import {AiOutlineLoading} from "react-icons/ai";
import axios from "axios";
import {baseUrl} from "../../../config";
import {toast} from "react-toastify";
import {setEventData, setEventName} from "../../../redux/slice/eventDataSlice";
import {useDispatch} from "react-redux";
import {setSelectedView} from "../../../redux/slice/tabSlice";

const ViewEvent = ({events}) => {
  const [formFields, setFormFields] = useState({eventName: ""});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const updateFormField = (name, value) => {
    setFormFields({...formFields, [name]: value});
  }

  const validateForm = () => {
    const requiredFields = ['eventName'];

    const fieldNames = {
      eventName: "event name",
    };

    for (let field of requiredFields) {
      if (!formFields[field]) {
        toast.error(`Please fill out the ${fieldNames[field]} field.`);
        return false;
      }
    }
    return true;
  }

  const submitForm = async () => {
    if(isLoading) return;

    if (!validateForm()) return;

    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}/view-event?event=${formFields.eventName}`);

      if(response.data.length === 0) {
        toast.error("No data found for this event");
      } else {
        dispatch(setEventData(response.data));
        dispatch(setEventName(formFields.eventName));
        dispatch(setSelectedView("VIEW"));
      }

    } catch (error) {
      console.error(error);
      toast.error(error.response.data || "Error trying to get event. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
      <div className="view">
        <label className="first-label" htmlFor="eventName">Select a Event</label>
        <Select
            id="eventName"
            name="eventName"
            options={events}
            onChange={(values) => {
              updateFormField("eventName", values[0].value);
            }}
            values={[]}
            placeholder="Select an option"
        />

        <div className="submit-button" onClick={() => submitForm()} style={{marginTop: "32px"}}>
          {isLoading ? <AiOutlineLoading/> : "Submit"}
        </div>
      </div>
  );
};

export default ViewEvent;
