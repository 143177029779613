import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  userEmail: "",
  isTokenLoading: true,
}

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserEmail: (state, data) => {
      state.userEmail = data.payload;
    },
    setTokenLoading: (state, data) => {
      state.isTokenLoading = data.payload;
    }
  }
});

export const {
  setUserEmail,
  setTokenLoading,
} = userSlice.actions;

export default userSlice.reducer;
