import {configureStore} from "@reduxjs/toolkit";
import tabSlice from "./slice/tabSlice";
import eventDataSlice from "./slice/eventDataSlice";
import userSlice from "./slice/userSlice";

export const store = configureStore({
  reducer: {
    tab: tabSlice,
    eventData: eventDataSlice,
    user: userSlice,
  }
});