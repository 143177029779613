import React, {useEffect, useState} from "react";
import "./Home.scss";
import axios from "axios";
import {baseUrl} from "../../config";
import {toast} from "react-toastify";
import UpdateForm from "./UpdateForm/UpdateForm";
import ViewEvent from "./ViewEvent/ViewEvent";

const Home = () => {
  const [selectedTab, setSelectedTab] = useState("VIEW_EVENT");
  const [events, setEvents] = useState([]);

  const views = {
    VIEW_EVENT: <ViewEvent events={events} />,
    UPDATE_EVENT: <UpdateForm events={events} />,
  }

  useEffect(() => {
    axios.get(`${baseUrl}/list-events`)
      .then((response) => {
        setEvents(response.data.map((event) => ({value: event, label: event})));
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error in fetching events");
      });
  }, []);

  return (
      <div className="home">
        <div className="select-area" style={{maxHeight: selectedTab === "VIEW_EVENT"? "300px" : "650px"}}>
          <div className="tab-selector">
            <div className={`tab ${selectedTab === "VIEW_EVENT" ? "tab-active" : ""}`}
                 onClick={() => setSelectedTab("VIEW_EVENT")}>View Event
            </div>
            <div className={`tab ${selectedTab === "UPDATE_EVENT" ? "tab-active" : ""}`}
               onClick={() => setSelectedTab("UPDATE_EVENT")}>Update Event
          </div>
        </div>

        {views[selectedTab]}
      </div>
    </div>
  );
}

export default Home;
