import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {baseUrl} from "../../../config";
import {toast} from "react-toastify";
import {MdDelete} from "react-icons/md";

const SessionTable = () => {
  const {sessionKeyList, eventName} = useSelector((state) => state.eventData);
  const [sessionData, setSessionData] = useState([]);

  useEffect(() => {
    getSessionData();
  }, [sessionKeyList]);

  const getSessionData = async() => {
    try{
      const response = await axios.get(`${baseUrl}/get-sessions?id=${sessionKeyList.join(",")}`);
      setSessionData(response.data);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data || "Error trying to get sessions.");
    }
  }

  const deleteHandler = async(sessionId) => {
    if (window.confirm("This action cannot be undone. Are you sure you want to delete this session?")) {
      try {
        await axios.delete(`${baseUrl}/session?id=${sessionId}&event=${eventName}`);
        window.location.reload();
      } catch (error) {
        console.error(error);
        toast.error("Error trying to delete session, Contact support.");
      }
    }
  }

  const displaySessionData = () => {
    return sessionData.map((session, index) => (
        <div key={index} className="session-details">
          <MdDelete onClick={() => deleteHandler(session.id)} />

          <div className="session-row">
            <div className="session-label">Session Name:</div>
            <div className="session-data">{session.name}</div>
            <div className="session-label">Host:</div>
            <div className="session-data">{session.host_name}</div>
            <div className="session-label">Duration:</div>
            <div className="session-data">{session.duration}</div>
            <div className="session-label">Dates:</div>
            <div className="session-data">
              {new Date(session.start_date).toLocaleString(undefined, { hour12: true })} - {new Date(session.end_date).toLocaleString(undefined, { hour12: true })}
            </div>
          </div>
          {index < sessionData.length - 1 && <div className="session-divider"></div>}
        </div>
    ));
  }

  return (
      <div className="session-data-section">
        <div className="session-header">Session Details</div>
        {displaySessionData()}
      </div>
  );
};

export default SessionTable;